<template>
  <div>
    <v-row no-gutters>
      <etapa-bloco
        v-for="(bloco, index) in etapas"
        :key="`bloco-${index}`"
        :item="bloco"
      />
    </v-row>
  </div>
</template>

<script>
import EtapaBloco from '@/components/dadosProposta/EtapaBloco.vue';

export default {
  data() {
    return {
      etapasPadrao: [
        {
          titulo: 'Dados Titular',
          subtitulo: 'Informações pessoais e endereços',
          icon: 'mdi-account',
        },
        {
          titulo: 'Dados Dependentes',
          subtitulo: 'Relação de dependentes',
          icon: 'mdi-account-group',
        },
        {
          titulo: 'Dados Plano',
          subtitulo: 'Mais informações do plano selecionado',
          icon: 'mdi-file-document',
        },
        {
          titulo: 'Dados Vigência',
          subtitulo: 'Informações sobre a vigência seleciona',
          icon: 'mdi-calendar-month',
        },
        {
          titulo: 'Dados Profissionais',
          subtitulo: 'Informações profissionais do titular',
          icon: 'mdi-account-tie',
        },
        {
          titulo: 'Dados Financeiros',
          subtitulo: 'Informações financeiras',
          icon: 'mdi-cash',
        },
      ],
    };
  },
  components: {
    EtapaBloco,
  },
  computed: {
    etapas() {
      const etapas = this.etapasPadrao;
      return etapas;
    },
  },
};
</script>

<style>

</style>
