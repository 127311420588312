var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        _vm._l(_vm.etapas, function (bloco, index) {
          return _c("etapa-bloco", {
            key: `bloco-${index}`,
            attrs: { item: bloco },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }