var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { staticClass: "mt-4", attrs: { cols: "12", md: "4", sm: "6" } },
    [
      _c(
        "v-card",
        {
          staticClass: "ml-4",
          attrs: { outlined: "", height: "100%" },
          on: {
            click: function ($event) {
              return _vm.openDialog("Contrato")
            },
          },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "espacamento-linhas pa-2" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-lg-center mt-1",
                      attrs: { cols: "2", md: "3" },
                    },
                    [
                      _c("v-icon", { attrs: { size: "45" } }, [
                        _vm._v(" " + _vm._s(_vm.icon)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-left", attrs: { cols: "8", md: "7" } },
                    [
                      _c("span", { staticClass: "subtitle-2 mb-0" }, [
                        _vm._v(_vm._s(_vm.titulo)),
                      ]),
                      _c("br"),
                      _c(
                        "span",
                        { staticClass: "caption espacamento-linhas" },
                        [_vm._v(_vm._s(_vm.subtitulo))]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-btn",
                        { staticClass: "mt-1", attrs: { icon: "" } },
                        [
                          _c("v-icon", { attrs: { size: "45" } }, [
                            _vm._v("mdi-chevron-right"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }