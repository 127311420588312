<template>
  <v-col cols="12" md="4" sm="6" class="mt-4">
    <v-card
      outlined
      class="ml-4"
      height="100%"
      @click="openDialog('Contrato')"
    >
      <v-card-text class="espacamento-linhas pa-2">
        <v-row no-gutters>
          <v-col cols="2" md="3" class="text-lg-center mt-1">
            <v-icon size="45"> {{ icon }}</v-icon>
          </v-col>
          <v-col cols="8" md="7" class="text-left">
            <span class="subtitle-2 mb-0">{{ titulo }}</span><br />
            <span class="caption espacamento-linhas"
              >{{ subtitulo }}</span
            >
          </v-col>
          <v-col cols="2" class="text-center">
            <v-btn icon class="mt-1">
              <v-icon size="45">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    titulo() {
      return this.item.titulo || '';
    },
    subtitulo() {
      return this.item.subtitulo || '';
    },
    icon() {
      return this.item.icon || '';
    },
    acao() {
      return this.item.acao || null;
    },
  },
};
</script>

<style>
.espacamento-linhas {
  line-height: 12px !important;
}
</style>
